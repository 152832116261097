tbody tr td div.editable {
  color: var(--ion-color-secondary);
  cursor: pointer;
  text-decoration: underline;
}

td.action-col {
  vertical-align: middle;
  text-align: center;
}

input[type="checkbox"] {
  vertical-align: middle;
}

th {
  overflow-x: auto;
  white-space: nowrap;
}

tr {
  height: 33;
}

td {
  overflow-x: hidden;
  text-rendering: auto;
  text-overflow: ellipsis;
  white-space: pre;
  max-width: 400px;
}

th.MuiTableCell-sizeSmall,
td.MuiTableCell-sizeSmall,
#menuItem {
  padding: 2px 10px 2px 10px;
  font-family: "Kanit";
  font-size: 12px;
}

.MuiSelect-select.MuiSelect-select {
  padding: 2px 10px 2px 0px;
  font-family: "Kanit";
  font-size: 12px;
}

p.MuiTablePagination-caption,
.MuiTablePagination-select.MuiSelect-select.MuiSelect-select {
  font-family: "Kanit";
  font-size: 15px;
}

th.MuiTableCell-sizeSmall {
  font-weight: 600;
}

/* tbody tr td div.non-editable {

  } */
