* {
  box-sizing: border-box;
}

body,
html,
.App,
#root,
.outer {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  // background-image: src("./assets/img/bg-01-03-scaled.jpg");
  background-image: url("/assets/img/bg-01-03-scaled.jpg");
  background-color: #1e1e1e;

  .inner {
    width: 600px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
  }
}

@media (max-width: 768px) {
  .outer {
    .inner {
      width: 100%;
      height: 100%;
      margin: auto;
      box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      padding: 40px 55px 45px 55px;
      border-radius: 0px;
      transition: all 0.3s;
      ion-col {
        text-align: center;
        .login-logo {
          flex-direction: column;
          justify-content: center;

          ion-text {
            font-size: 40px;
            margin: 0px;
          }
        }
      }
    }
  }
}

.login-logo {
  display: flex;
  align-items: center;
  
  ion-text {
    font-size: 50px;
    font-weight: bold;
    font-family: "Raleway";
    margin-left: 20px;
  }
}
