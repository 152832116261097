ion-button {
  font-size: 12px;
}

ion-item-divider {
  border-bottom: 0px;
}

ion-item-divider ion-label {
  margin: 0px;
}

ion-item ion-label {
  margin: 0px;
}

ion-item#prj-header {
  --background: #e8e9ec;
}

ion-item#epic-header {
  --background: #f4f5f8;
}

ion-item#emp-header {
  --background: #f5f6f8;
}

#acm_fill {
  padding: 0px 5px 0px 5px;
  margin: 0px;
}
