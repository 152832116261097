ion-menu ion-content {
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #1e1e1e)
  );
}

ion-menu ion-list.md {
  padding-top: 10px;
  background: var(--ion-item-background, var(--ion-background-color, #1e1e1e));
  border-bottom: medium solid var(--ion-color-step-150, #262626);
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list-header {
  padding-left: 10px;
  --color: var(--ion-text-color, #fff);
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  min-height: 20px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 3px solid var(--ion-color-step-150, #262626);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 18px;
  font-weight: 500;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
  --max-height: 10px;
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #1e1e1e)
  );
  --color: #757575;
}

ion-menu.md ion-item:hover {
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #2a2a2a)
  );
  --color: #ffffff;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
  color: var(--ion-color-primary);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-list {
  background: var(--ion-item-background, var(--ion-background-color, #1e1e1e));
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #1e1e1e)
  );
  --color: #757575;
}

ion-menu.ios ion-item:hover {
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #2a2a2a)
  );
  --color: #ffffff;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
  color: var(--ion-color-primary);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  --color: var(--ion-text-color, #fff);
}

ion-menu.ios ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-header {
  border-bottom: 3px solid var(--ion-color-step-150, #262626);
  background-color: var(
    --ion-item-background,
    var(--ion-background-color, #1e1e1e)
  );
  padding: 10px;
}

ion-menu ion-header ion-toolbar {
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #1e1e1e)
  );
  color: #757575;
}

ion-menu ion-title {
  margin: 0px;
  text-align: start;
  display: block;
  font-size: 25px;
  --color: var(--ion-text-color, #fff);
  font-weight: 600;
}

ion-menu.ios ion-title {
  padding-left: 90px;
  padding-right: 10px;
}

ion-menu .header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0px;
}

ion-menu ion-note {
  display: block;
  font-size: 12px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
